// Footer.tsx
import React, { useEffect, useState } from 'react'
import './Header.scss'
import { styled } from '@fluentui/react'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
const cookies = new Cookies()
const Header: React.FC = () => {
  const history = useHistory()
  const Loggedin = cookies.get('Loggedin')
  // console.log("raj :"+Loggedin)
  function changeClass() {
    const nav = document.getElementById('navbar')
    const toggle = document.getElementById('toggle-btn')
    const toggleClose = document.getElementById('toggle-close-btn')
    nav?.classList.add('navbar-mobile')
    toggle?.classList.remove('d-block')
    toggle?.classList.add('d-none')
    toggleClose?.classList.remove('d-none')
    toggleClose?.classList.add('d-block')
  }

  function removeMoblieNav() {
    const nav = document.getElementById('navbar')
    const toggle = document.getElementById('toggle-btn')
    const toggleClose = document.getElementById('toggle-close-btn')
    nav?.classList.remove('navbar-mobile')
    toggle?.classList.remove('d-none')
    toggle?.classList.add('d-block')
    toggleClose?.classList.remove('d-block')
    toggleClose?.classList.add('d-none')
  }
  function deleteAllCookies() {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    }
  }
  const handleLogout = () => {
    // Perform any necessary logout actions
    // Delete all cookies
    deleteAllCookies()
    // Redirect the user to the login page or any other desired page
    // (assuming you are using React Router for navigation)
    // history.push('/login')
    window.location.href = '/login'
  }

  return (
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <h1>
            <a href="/login">
              <img className="bg img-fluid" src={require('assets/images/WellFie3.png').default} title="" alt="" />
            </a>
          </h1>
        </div>
        <nav id="navbar" className="navbar">
          <ul>
            {Loggedin !== "true" ?
              <li><a className="nav-link scrollto" href="/login">Take Wellfie Test</a></li>
              :
              <>
                <li><a className="nav-link scrollto" href="/patient-details-form">Take Wellfie Test</a></li>
                <li><a className="nav-link scrollto" href="/dashboard">Dashboard</a></li>
                <li><a className="nav-link scrollto" href="/reports">Reports</a></li>
                <li><button className='btn btn-danger btn-nav' onClick={handleLogout}>Logout</button></li>
              </>
            }
          </ul>
          <i className="bi bi-list mobile-nav-toggle d-block d-xl-none d-lg-none d-md-none" id="toggle-btn"
            onClick={changeClass}></i>
          <i className="bi bi-x mobile-nav-toggle d-none" id="toggle-close-btn"
            onClick={removeMoblieNav}></i>
        </nav>
      </div>
    </header>
  )
}

export default Header
