// Footer.tsx
import React from 'react'
import './Footer.scss'
const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="copyright">
            &copy; Copyright
          <strong>
            <span>© 2024  BWell HealthTech Private Limited</span>
          </strong>
            . All Rights Reserved | Startup India Recognition - DIPP80159
        </div>
      </div>
    </footer>
  )
}

export default Footer
