import { Loader } from '@fluentui/react-northstar'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { useTeamsFx } from '@microsoft/teamsfx-react'
import { TeamsFxContext } from './Context'
import { TeamsTheme } from '@fluentui/react-teams/lib/cjs/themes'
import { Provider } from '@fluentui/react-teams'
import './App.scss'
import { lazy, Suspense } from 'react'
import CapturePermissions from 'tabs/CaptureTab/Views/Capture/CapturePermissions'
import Header from 'tabs/CaptureTab/Views/Header/Header'
import Footer from 'tabs/CaptureTab/Views/Footer/Footer'
// Lazy loading components
const Capture = lazy(() =>
  import(/* webpackChunkName: "capture" */ 'tabs/CaptureTab/Views/Capture/Capture'))
const ErrorNotification = lazy(() =>
  import(/* webpackChunkName: "error-notification" */ 'tabs/CaptureTab/Views/ErrorNotification/ErrorNotification'))
const BadConditions = lazy(() =>
  import(/* webpackChunkName: "bad-conditions" */ 'tabs/CaptureTab/Views/BadConditions/BadConditions'))

const Results = lazy(() =>
  import(/* webpackChunkName: "results" */ 'tabs/CaptureTab/Views/Results/Results'))

const PatientDetailsForm = lazy(() =>
  import(/* webpackChunkName: "patient-details-form" */ 'tabs/CaptureTab/Views/PatientDetailsForm/PatientDetailsForm'))

const PatientIdForm = lazy(() =>
  import(/* webpackChunkName: "patient-id-form" */ 'tabs/CaptureTab/Views/PatientIdForm/PatientIdForm'))

const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ 'tabs/CaptureTab/Views/Login/Login'))

const Reports = lazy(() =>
  import(/* webpackChunkName: "reports" */ 'tabs/CaptureTab/Views/Reports/Reports'))

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'tabs/CaptureTab/Views/Dashboard/Dashboard'))

export default function App() {
  const { loading, theme, themeString, teamsfx } = useTeamsFx()

  return (
    <div>
      <TeamsFxContext.Provider value={{ theme, themeString, teamsfx }}>
        <Provider themeName={TeamsTheme.Dark} lang="en-US">
          <BrowserRouter>
            <Header />
            <Route exact path="/">
              <Redirect to={'/login'} />
            </Route>
            {loading ? (
              <Loader style={{ margin: 100 }} label={'just a moment...'} />
            ) : (
              <Suspense fallback={<Loader style={{ margin: 100 }} label='just a moment...' />}>
                <Route exact path="/patient-details-form" component={PatientDetailsForm} />
                <Route exact path="/patient-id-form" component={PatientIdForm} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/login" component={Login} />
                <Route path="/capture" render={({ match: { url } }) => (
                  <>
                    <Route exact path={`${url}/`} component={Capture} />
                    <Route exact path={`${url}/permissions`} component={CapturePermissions} />
                    <Route exact path={`${url}/error`} component={ErrorNotification} />
                    <Route exact path={`${url}/bad-conditions`} component={BadConditions} />
                    <Route exact path={`${url}/results`} component={Results} />
                  </>
                )} />
              </Suspense>
            )}
            <Footer />
          </BrowserRouter>
        </Provider>
      </TeamsFxContext.Provider>
    </div>
  )
}
